<template>
    <footer class="footer" :class="isLoggedIn ? 'logged-in' : ''">
        <div class="container">
            <div class="footer__content f16">
                <ul class="footer__list flex-grow-1 gap-3 gap-lg-4">
                    <li class="footer__list-item flex-grow-1">
                        Copyright © {{ new Date().getFullYear()}} Elavon Connect
                    </li>
                    <li class="footer__list-item flex-grow-0">
                        <a :href="publicPath + '/assets/app/files/TF_Regulamin__Klub_Korzyści_Elavon_II.pdf?v='+date" target="_blank" class="footer__link">Regulamin Klubu Korzyści wraz z&nbsp;zasadami przetwarzania&nbsp;danych</a>
                    </li>
                    <li class="footer__list-item">
                        <a :href="publicPath + '/assets/app/files/Polityka_Cookies_v1.pdf?v='+date" target="_blank" class="footer__link">Polityka dot. plików&nbsp;cookies</a>
                    </li>
                    <li class="footer__list-item">
                        <router-link :to="{name: 'cookies'}" class="footer__link">Preferencje&nbsp;cookies</router-link>
                    </li>
                </ul>
            </div>
            <div class="d-flex justify-content-center justify-content-lg-end">

                <div class="footer__social">
                    <a href="https://twitter.com/ElavonEurope" target="_blank" class="footer__social-link">
                        <svg id="btn_twitter" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_2805" data-name="Rectangle 2805" width="25.535" height="20.75" fill="#58585a"/>
                                </clipPath>
                            </defs>
                            <g id="Rectangle_2807" data-name="Rectangle 2807" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                                <rect width="40" height="40" stroke="none"/>
                                <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
                            </g>
                            <g id="Group_29898" data-name="Group 29898" transform="translate(7.233 9.625)" opacity="1">
                                <g id="Group_29897" data-name="Group 29897" transform="translate(0 0)" clip-path="url(#clip-path)">
                                    <path id="Path_8674" data-name="Path 8674" d="M25.535,2.457a10.43,10.43,0,0,1-3.009.825,5.255,5.255,0,0,0,2.3-2.9A10.46,10.46,0,0,1,21.5,1.654a5.244,5.244,0,0,0-8.927,4.778A14.869,14.869,0,0,1,1.778.959,5.242,5.242,0,0,0,3.4,7.953,5.212,5.212,0,0,1,1.026,7.3c0,.022,0,.044,0,.066a5.242,5.242,0,0,0,4.2,5.137,5.259,5.259,0,0,1-2.366.089,5.242,5.242,0,0,0,4.893,3.638A10.518,10.518,0,0,1,1.249,18.47,10.582,10.582,0,0,1,0,18.4,14.833,14.833,0,0,0,8.031,20.75a14.8,14.8,0,0,0,14.9-14.9c0-.227-.006-.453-.016-.678a10.584,10.584,0,0,0,2.616-2.71" transform="translate(0 0)" fill="#58585a"/>
                                </g>
                            </g>
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/company/elavon-europe/" target="_blank" class="footer__social-link">
                        <svg id="btn_linkedin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_2806" data-name="Rectangle 2806" width="22.343" height="22.302" fill="#58585a"/>
                                </clipPath>
                            </defs>
                            <g id="Rectangle_2809" data-name="Rectangle 2809" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                                <rect width="40" height="40" stroke="none"/>
                                <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
                            </g>
                            <g id="Group_29900" data-name="Group 29900" transform="translate(9.228 7.222)" opacity="1">
                                <g id="Group_29899" data-name="Group 29899" transform="translate(0 0)" clip-path="url(#clip-path)">
                                    <path id="Path_8675" data-name="Path 8675" d="M5,22.3H.368V7.406H5ZM2.687,5.37A2.685,2.685,0,1,1,5.369,2.684,2.685,2.685,0,0,1,2.687,5.37M22.343,22.3H17.714V15.058c0-1.727-.03-3.95-2.406-3.95-2.41,0-2.777,1.882-2.777,3.826V22.3H7.906V7.406h4.438V9.442h.064a4.864,4.864,0,0,1,4.381-2.406c4.687,0,5.553,3.084,5.553,7.1Z" transform="translate(0 0)" fill="#58585a"/>
                                </g>
                            </g>
                        </svg>
                    </a>
                    <a href="https://www.youtube.com/channel/UCksYVaVmDEKCeJSDNqY-jwQ" target="_blank" class="footer__social-link">
                        <svg id="btn_yt" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_2804" data-name="Rectangle 2804" width="26.045" height="18.32" fill="#58585a"/>
                                </clipPath>
                            </defs>
                            <g id="Rectangle_2810" data-name="Rectangle 2810" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                                <rect width="40" height="40" stroke="none"/>
                                <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
                            </g>
                            <g id="Group_29896" data-name="Group 29896" transform="translate(6.977 11.094)" opacity="1">
                                <g id="Group_29895" data-name="Group 29895" transform="translate(0 0)" clip-path="url(#clip-path)">
                                    <path id="Path_8673" data-name="Path 8673" d="M25.786,3.952A5.633,5.633,0,0,0,24.75,1.367a3.727,3.727,0,0,0-2.61-1.1C18.5,0,13.028,0,13.028,0h-.012S7.549,0,3.905.264A3.725,3.725,0,0,0,1.3,1.367,5.623,5.623,0,0,0,.26,3.952,39.406,39.406,0,0,0,0,8.168v1.976a39.4,39.4,0,0,0,.26,4.215A5.626,5.626,0,0,0,1.3,16.943a4.409,4.409,0,0,0,2.872,1.114c2.084.2,8.856.262,8.856.262s5.472-.008,9.118-.272a3.726,3.726,0,0,0,2.609-1.1,5.634,5.634,0,0,0,1.036-2.584,39.67,39.67,0,0,0,.259-4.216V8.168a39.527,39.527,0,0,0-.26-4.216M10,12.88v-8L17.7,8.892Z" transform="translate(0 0)" fill="#58585a"/>
                                </g>
                            </g>
                        </svg>
                    </a>
                    <a href="https://pl-pl.facebook.com/ElavonInc/" target="_blank" class="footer__social-link">
                        <svg id="btn_fb" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_2803" data-name="Rectangle 2803" width="12.183" height="23.604" fill="#58585a"/>
                                </clipPath>
                            </defs>
                            <g id="Rectangle_2811" data-name="Rectangle 2811" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                                <rect width="40" height="40" stroke="none"/>
                                <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
                            </g>
                            <g id="Group_29894" data-name="Group 29894" transform="translate(14.167 8.198)" opacity="1">
                                <g id="Group_29893" data-name="Group 29893" clip-path="url(#clip-path)">
                                    <path id="Path_8672" data-name="Path 8672" d="M3.806,23.6V12.939H0v-3.8H3.807V5.627C3.807,2.086,5.9,0,9.055,0a23.828,23.828,0,0,1,3.128.212V4.189H9.64C7.923,4.188,7.6,5.04,7.6,6.237v2.9H12l-.621,3.8H7.6V23.6Z" fill="#58585a"/>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="privacy f12 mb-4">
                Elavon Financial Services Designated Activity Company (Spółka z&nbsp;Ograniczoną Odpowiedzialnością o&nbsp;Wyznaczonym Przedmiocie Działalności) Oddział w&nbsp;Polsce z&nbsp;siedzibą w&nbsp;Warszawie, ul.&nbsp;Puławska 17, 02-515 Warszawa, zarejestrowany w&nbsp;rejestrze przedsiębiorców Krajowego Rejestru Sądowego prowadzonym przez Sąd Rejonowy dla m.st. Warszawy w&nbsp;Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem
                KRS 287836, numer REGON 300649197, NIP 2090000825, kapitał zakładowy Elavon Financial Services Designated Activity Company 6.400.001,00 euro.
                Elavon Financial Services DAC prowadzi działalność gospodarczą pod nazwą Elavon Merchant Services i&nbsp;podlega nadzorowi Centralnego Banku Irlandii.
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
    data(){
        return{
            //publicPath: process.env.BASE_URL;
            publicPath: window.location.origin
        }
    },
    computed:{
        date(){
            return new Date().getTime()
        },
        isLoggedIn: function(){
            return this.$store.getters.isLoggedIn;
        },
    }
}
</script>

<style scoped lang="scss">
    .footer {
        position: relative;
        z-index: 1;
        background: #023E4D;
        &.logged-in{
            background: #04647C;
        }
        color: white;
        margin-top: 2rem;
        &__content {
            padding: 20px 0 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 991px) {
                flex-direction: column;
            }
        }

        &__list {
            padding-left: 0;
            list-style: none;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
            @media screen and (max-width: 991px) {
                flex-direction: column;
                text-align: center;
            }
        }

        &__list-item {
            &:first-of-type{
                white-space: nowrap;
            }
        }

        &__link {
            font-weight: 500;
            color: white;
            text-underline-offset: 3px;

            &:hover {
                color: white;
            }
        }
        .footer__social{
            min-width: 170px;
            margin-bottom: 1rem;
        }
        &__social-link {

            color: white;
            svg g g path {
                fill: rgba(255,255,255, 0.5);
                transition: all 0.3s;
            }
            &:hover {
                svg g g path {
                    fill: rgba(255,255,255, 1);
                }
            }
        }
        .privacy{
            line-height: 1.5;
        }
    }
</style>
