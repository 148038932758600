<template>
    <div class="popup fade" :class="{show: visible, 'no-padding': padding}">
        <div class="popup-backdrop fade"></div>
        <div class="popup-dialog" :class="[`popup-dialog-${size}`, {'popup-dialog-centered': centered}]">
            <div class="popup-content">
                <div v-if="!hideClose" class="popup-header">
                    <button class="close" @click="close"></button>
                    <h4 class="text-center">{{ title }}</h4>
                </div>
                <div class="popup-body" :class="{'pt-4': !!title, 'no-padding': padding}">
                    <div v-if="typeof body === 'string'" v-dompurify-html="body"/>
                    <component v-else :is="body" v-bind="props"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "PopupComponent",
    computed: {
        ...mapState({
            visible: state => state.popup.visible,
            size: state => state.popup.payload.size,
            centered: state => state.popup.payload.centered,
            title: state => state.popup.payload.title,
            body: state => state.popup.payload.body,
            props: state => state.popup.payload.props,
            padding: state => state.popup.payload.padding,
            hideClose: state => state.popup.payload.hideClose
        })
    },
    methods: {
        close: function () {
            this.$store.commit('hidePopup');
        }
    },
    mounted() {
        this.$el.addEventListener('click', (e) => {
            ['popup-dialog', 'popup-backdrop'].some(c => {
                if (e.target.classList.value.includes(c)) {
                    this.close()
                }
            })
        })

        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.close()
            }
        })
    }
}
</script>

<style scoped lang="scss">
.popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999992;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;

    &.show {
        opacity: 1;
        visibility: visible;

        .popup {
            &-backdrop {
                opacity: 0.96;
            }

            &-dialog {
                transform: none;
            }
        }
    }

    &-backdrop {
        width: 100vw;
        height: 100vh;
        background-color: rgba(4,66,81,0.75);
        opacity: 0;
        position: fixed;
    }

    &-dialog {
        display: flex;
        align-items: center;
        margin: auto;
        position: relative;
        width: auto;
        padding: 0.75rem;
        transform: translateY(-50px);
        transition: transform .2s;

        &-centered {
            min-height: 100%;
        }

        &-sm {
            max-width: 410px;
        }

        &-md {
            max-width: 610px;
        }

        &-lg {
            max-width: 810px;
        }

        &-xl {
            max-width: 1140px;
        }
    }

    &-content {
        background-color: #fff;
        border-radius: 12px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &-header {
        padding: 1.5rem 2rem 0;
        text-align: right;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 5;

        .close {
            padding: 0;
            width: 1.5rem;
            height: 1.5rem;
            background: url("@/img/close-popup.png") center no-repeat;
            background-size: cover;
            border: none;
        }
    }

    &-body {
        position: relative;
        flex: 1 1 auto;
        padding: 0 2rem 3rem;

        &.no-padding {
            padding: 0 2rem 15px 0;
            @media screen and (max-width: 991px) {
                padding: 1.5rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        &-header {
            //padding: 1.5rem 1.5rem 0;
        }

        &-body {
            padding: 1.5rem;
        }
    }

}

.fade {
    transition: .15s;
}
</style>
