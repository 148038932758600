<template>
    <div class="loading-wrapper">
        <span class="loader"></span>
    </div>
</template>
<script>
export default {
    name: "LoaderComponent",
}
</script>

<style lang="scss" scoped>
.loader{
    width: 100px;
    height: 100px;
}
.loading-wrapper {
    position: fixed;
    background: #{$color-blue-light}44;
    width: 100vw;
    height: 100vh;
    z-index: 999991;
    top: 0px;
    left: 0px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
