const payloads = [
    // {
    //     id: 1,
    //     first_name: 'Dawid',
    //     last_name: 'Kwiatkowski',
    //     job: 'Direct Sales R1',
    //     points: 134,
    //     position: 3,
    // },
    // {
    //     id: 2,
    //     first_name: 'Natalia',
    //     last_name: 'Kukulska',
    //     job: 'Direct Sales R1',
    //     points: 124,
    //     position: 13,
    // },
    // {
    //     id: 3,
    //     first_name: 'Anita',
    //     last_name: 'Lipnicka',
    //     job: 'Direct Sales R1',
    //     points: 173,
    //     position: 11,
    // }
]

const team = {
    state: {
        team:{
            members: payloads
        }
    },
    mutations: {
        setTeam: function (state, payload) {
            state.team.members = payload;
        },
    },
    actions: {
        getTeam: function (context) {
            context.commit('increaseLoader');
            window.api.get('user/team/members')
                .then(response => {
                    context.commit('setTeam', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
    },
    getters: {
        getTeam: function (state) {
            return state.team;
        },
    }
}

export default team;
