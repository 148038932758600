

const news = {
    state: {
        categories: [],
        material_products: {
            products: [],
            meta: {
                total: 0,
                count: 0,
                per_page: 6,
                current_page: 1,
                total_pages: 1,
            }
        },
        material_product: {},
        premium_products: [],
        premium_product: {},
        home_material_products: [],
        home_premium_products: [],
        search: '',
        sort_index: 0,
        sort: 'points',
        sort_dir: 'asc',
        page: 1,
        filters: {
            available: true,
            categories: [],
            all: false,
            price: {
                min: null,
                max: null
            },
        }
    },
    mutations: {
        setCategories: function (state, payload) {
            state.categories = payload;
        },
        setFilters: function (state, payload){
            state.filters = payload;
        },
        setHomeMaterialProducts: function (state, payload){
            state.home_material_products = payload;
        },
        setHomePremiumProducts: function (state, payload){
            state.home_premium_products = payload;
        },
        setMaterialProducts: function (state, payload){
            state.material_products = payload;
        },
        setMaterialProduct: function (state, payload){
            state.material_product = payload;
        },
        setPage: function (state, payload){
            state.page = payload;
        },
        setPremiumProducts: function (state, payload){
            state.premium_products = payload;
        },
        setPremiumProduct: function (state, payload){
            state.premium_product = payload;
        },
        setSearch: function (state, payload){
            state.search = payload;
        },
        setMaterialProductsSortIndex: function (state, payload){
            state.sort_index = payload;
        },
        setMaterialProductsSort: function (state, payload){
            let sort = payload.split('_');
            state.sort = sort[0];
            state.sort_dir = sort[1];
        }
    },
    actions: {
        getCategories: function (context) {
            context.commit('increaseLoader');
            window.api.get('product/categories')
                .then(response => {
                    context.commit('setCategories', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
        setFilters: function (context, payload){
            context.commit('setFilters', payload);
            context.dispatch('getMaterialProducts');
        },
        setHomeMaterialProducts: function (context){
            context.commit('increaseLoader');
            window.api.get('product?limit=10&categories=1&min=2000&max=20000')
                .then(response => {
                    context.commit('setHomeMaterialProducts', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                });
        },
        setHomePremiumProducts: function (context){
            context.commit('increaseLoader');
            window.api.get('product/premium')
                .then(response => {
                    context.commit('setHomePremiumProducts', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                });
        },
        setPage: function (context, payload){
            context.commit('setPage', payload);
            context.dispatch('getMaterialProducts');
        },
        setSearch: function (context, payload){
            context.commit('setSearch', payload);
            context.dispatch('getMaterialProducts');
        },
        setMaterialProductsSort: function (context, payload){
          context.commit('setMaterialProductsSortIndex', payload.sortIndex);
          context.commit('setMaterialProductsSort', payload.sortField);
          context.dispatch('getMaterialProducts');
        },
        getMaterialProducts: function (context) {
            context.commit('increaseLoader');
            let url = 'product';
            url += '?page='+context.state.page+'&sort='+context.state.sort+'&dir='+context.state.sort_dir+'&search='+context.state.search+'&limit=8';
            if(context.state.filters.available){
                url += '&available=true';
            }
            if(context.state.filters.categories.length){
                url += '&categories=' + context.state.filters.categories.join(',');
            }
            if(context.state.filters.price.min){
                url += '&min=' + context.state.filters.price.min;
            }
            if(context.state.filters.price.max){
                url += '&max=' + context.state.filters.price.max;
            }

            window.api.get(url)
                .then(response => {
                    context.commit('setMaterialProducts', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
        getMaterialProduct: function (context, id) {
            context.commit('increaseLoader');
            window.api.get('product/' + id)
                .then(response => {
                    context.commit('setMaterialProduct', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
        getPremiumProducts: function (context) {
            context.commit('increaseLoader');
            window.api.get('product/premium')
                .then(response => {
                    context.commit('setPremiumProducts', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
        getPremiumProduct: function (context, id) {
            context.commit('increaseLoader');
            window.api.get('product/premium/'+id)
                .then(response => {
                    context.commit('setPremiumProduct', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
    },
    getters: {
        getCategories: function (state) {
            return state.categories;
        },
        getFilters: function (state){
            return state.filters;
        },
        getHomeMaterialProducts: function (state){
            return state.home_material_products;
        },
        getHomePremiumProducts: function (state){
            return state.home_premium_products;
        },
        getMaterialProducts: function (state){
            return state.material_products;
        },
        getMaterialProduct: function (state){
            return state.material_product;
        },
        getPremiumProducts: function (state){
            return state.premium_products;
        },
        getPremiumProduct: function (state){
            return state.premium_product;
        },
        getPage: function (state){
            return state.page;
        },
        getSearch: function (state){
            return state.search;
        },
        getMaterialProductsSortIndex: function (state){
            return state.sort_index;
        }
    }
}

export default news;
