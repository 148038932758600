import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "@/router/routes";
import store from '@/store/index';

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    base: '/',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition ? savedPosition : {x: 0, y: 0}
    },
})


router.beforeEach(async function (to, from, next) {

    if (to.meta.requiresAuth) {
        store.getters.isLoggedIn ? next() : next({name: 'login'});
    } else if(to.name === 'cookies' ) {
        next();
    } else if (store.getters.isLoggedIn) {
        next({name: 'home'})
    } else {
        next();
    }

    if (store.getters.isLoggedIn) {
        store.dispatch('getUser');
    //     await store.dispatch('getAgreements');
    //     if (store.getters.checkAgreements !== 1 && to.name !== 'checkAgreements'){
    //         next({name: 'checkAgreements'})
    //     } else {
    //         next();
    //     }
    }

    // store.commit('toggleMenu', false);
});

export default router
