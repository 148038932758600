// const demo_payload = [
//     { position: 1, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 2, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 3, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 4, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 5, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 6, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 7, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 8, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 9, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 10, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 11, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 12, user: 'Karol M.', team: 'Direct Sales R2', score: 1800},
//     { position: 13, user: 'Karol M.', team: 'Direct Sales R2', score: 1800}
// ];

const ranking = {
    state: {
        home_ranking_individual: [],
        home_ranking_teams: [],
        general: [],
        individual: [],
        teams: [],
        individual_filter: '',
        teams_filter: '',
        filters_range: [
            // '2022-08': 'Sierpień 2022',
            // '2022-09': 'Wrzesień 2022',
            // '2022-10': 'Październik 2022',
            // '2022-11': 'Listopad 2022',
            // '2022-12': 'Grudzień 2022',
            // '2023-01': 'Styczeń 2023'
        ],
        hide_ranking: false
    },
    mutations: {
        setHomeRankingIndividual: function (state, payload){
            state.home_ranking_individual = payload;
        },
        setHomeRankingTeams: function (state, payload){
            state.home_ranking_teams = payload;
        },
        setGeneral: function (state, payload){
            state.general = payload;
        },
        setIndividual: function (state, payload){
            state.individual = payload;
        },
        setIndividualFilter: function (state, payload){
            state.individual_filter = payload;
        },
        setTeams: function (state, payload){
            state.teams = payload;
        },
        setTeamsFilter: function (state, payload){
            state.teams_filter = payload;
        },
        setFiltersRange: function (state, payload) {
            state.filters_range = payload;
        },
    },
    actions: {
        setHomeRankingIndividual: function (context, currentPeriodId){
            context.commit('increaseLoader');
            window.api.get('ranking/individual/'+ currentPeriodId + '?limit=10000')
                .then(response => {
                    context.commit('setHomeRankingIndividual', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                });
        },
        setHomeRankingTeams: function (context, currentPeriodId){
            context.commit('increaseLoader');
            window.api.get('ranking/team/' + currentPeriodId)
                .then(response => {
                    context.commit('setHomeRankingTeams', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                });
        },
        setGeneral: function (context){
            context.commit('increaseLoader');
            window.api.get('ranking/individual/?limit=10000')
                .then(response => {
                    context.commit('setGeneral', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                });
        },
        setIndividual: function (context){
            context.commit('increaseLoader');
            let filter = '';
            if(context.state.individual_filter !== ''){
                filter = context.state.individual_filter;
            }
            window.api.get('ranking/individual/'+filter + '?limit=10000')
                .then(response => {
                    context.commit('setIndividual', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                });
        },
        setIndividualFilter: function (context, filter){
            context.commit('setIndividualFilter', filter)
            context.dispatch('setIndividual');

        },
        setTeams: function (context){
            context.commit('increaseLoader');
            let filter = '';
            if(context.state.teams_filter !== ''){
                filter = context.state.teams_filter;
            }
            window.api.get('ranking/team/' + filter)
                .then(response => {
                    context.commit('setTeams', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                });
        },
        setTeamsFilter: function (context, filter){
            context.commit('setTeamsFilter', filter)
            context.dispatch('setTeams');
        },
        getFiltersRange: function (context) {
            context.commit('increaseLoader');
            window.api.get('period')
                .then(response => {
                    context.commit('setFiltersRange', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
    },
    getters: {
        hideRanking: function (state){
            return state.hide_ranking;
        },
        getHomeRankingIndividual: function (state){
            return state.home_ranking_individual;
        },
        getHomeRankingTeams: function (state){
            return state.home_ranking_teams;
        },
        getGeneral: function (state){
            return state.general;
        },
        getIndividual: function (state){
            return state.individual;
        },
        getTeams: function (state){
            return state.teams;
        },
        getFiltersRange: function (state) {
            return state.filters_range;
        }
    }
}

export default ranking;
