export const LINK_TARGET_BYPASS = 'data-target';

export const USER_ROLES = {
    REPRESENTANTIVE: 'representantive',
    MANAGER: 'manager'
}

export const ORDER_STATUSES = {
    NEW: 'Nowy',
    IN_PROGRESS: 'W trakcie',
    CANCELLED: 'Anulowany',
    SENT: 'Wysłany'
}
