import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueApexCharts from 'vue-apexcharts'
import {LINK_TARGET_BYPASS} from "@/store/constants";
Vue.config.productionTip = false;

Vue.use(VueDOMPurifyHTML, {
    hooks: {
        afterSanitizeAttributes: (node) => {
            if ('target' in node) {
                if(node.getAttribute(LINK_TARGET_BYPASS) !== null){
                    node.setAttribute('target', node.getAttribute(LINK_TARGET_BYPASS));
                }
            }
        }
    }
});
Vue.use(VueApexCharts);

Vue.component('apex-chart', VueApexCharts)

import api from './helpers/api';
window.api = api;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
