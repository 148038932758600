const payload = {
    stream: null,
    users: [],
    team: null,
}

const winner = {
    state: {
        winner: payload,
    },
    mutations: {
        setWinner: function (state, payload) {
            state.winner = payload;
        },
    },
    actions: {
        getWinner: function (context, payload) {
            context.commit('increaseLoader');
            window.api.get('ranking/winners/' + payload)
                .then(response => {
                    context.commit('setWinner', response.data.data)
                })
                .catch((e)=>{
                    let message = 'Wystąpił błąd podczas pobierania danych zwycięzców.';
                    if(e.response.data.message !== undefined && e.response.data.message !== ''){
                        message = e.response.data.message;
                    }
                    context.commit('showPopup', {
                        body: '<p class="p-4">' + message + '</p>',
                        padding: true,
                    });
                    context.commit('setWinner', payload)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
    },
    getters: {
        getWinner: function (state) {
            return state.winner;
        },
    }
}

export default winner;
