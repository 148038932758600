<template>
    <transition name="fade">
      <div v-if="show" class="cookie-banner shadow">
          <div class="container pt-3 pt-sm-5 mb-4">
            <h2 class="mb-3 mb-sm-4">Pliki cookies na konkurs-klubkorzysci.pl</h2>
            <div class="row mb-2">
                <div class="col-12 col-lg-8 ">
                    <p>
                        Powiedz nam, czy akceptujesz politykę plików Cookies.
                    </p><p>
                        Używamy plików Cookies do gromadzenia informacji o&nbsp;tym, w&nbsp;jaki sposób korzystasz z&nbsp;konkurs-klubkorzysci.pl. Informacje to są używane w&nbsp;celu działania strony internetowej w&nbsp;jak najlepszy sposób, oraz ulepszania naszych usług.
                    </p>
                </div>
            </div>
            <div>
                <a href="#" @click.prevent="acceptCookies" class="btn blue me-3 mb-3">Zaakceptuj wszystkie pliki cookie</a>
                <router-link :to="{name: 'cookies'}" class="btn blue-inverted mb-3">Ustaw preferencje plików Cookies</router-link>
            </div>
          </div>
      </div>
    </transition>
</template>

<script>
export default {
    name: "CookieBannerComponent",
    data: function(){
        return {
            show: false,
            //publicPath: process.env.BASE_URL;
            publicPath: window.location.origin
        }
    },
    mounted() {
        this.init();
    },
    methods:{
        init(){
            if( !localStorage.getItem('elavon-cookie-required') ){
                this.show = true;
            }
        },
        acceptCookies(){
            localStorage.setItem('elavon-cookie-required', 1);
            localStorage.setItem('elavon-cookie-analytical', 1);
            localStorage.setItem('elavon-cookie-marketing', 1);
            localStorage.setItem('elavon-cookie-performance', 1);
            localStorage.setItem('elavon-cookie-statistical', 1);
            this.show = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.cookie-banner{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background: $color-main;
    color: white;
    z-index: 999991;
}
</style>
