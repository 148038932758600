import Vue from 'vue'
import Vuex from 'vuex'

import basket from './states/basket'
import loader from './states/loader'
import news from './states/news'
import popup from './states/popup'
import prizes from './states/prizes'
import ranking from './states/ranking'
import registration from './states/registration'
import team from './states/team';
import training from './states/training';
import user from './states/user';
import winner from './states/winner';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
      basket,
      loader,
      news,
      popup,
      prizes,
      ranking,
      registration,
      team,
      training,
      user,
      winner,
  }
})
