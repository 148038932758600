import router from "@/router";
import {USER_ROLES} from "@/store/constants";

const payload = {
    elavon_id: null,
    elavon_role: "-",
    first_name: "-",
    last_name: "-",
    email: "-",
    team_id: "-",
    team_name: "-",
    team_leader: 0,
    registered: "-",
    avatar: "",
    cart_items: 0,
    saldo: 0,
    scores: {
        team_score: 0,
        team_position: 0,
        target: null,
        deals_signed: 0,
        user_score: 0,
        user_position: 0,
        details: {
            long_term_direct_sales: 0,
            direct_short_term_sales: 0,
            long_term_sale_of_dkf: 0,
            short_term_sale_of_dkf: 0,
            activation_of_the_contract: 0,
            cash_register_integration: 0,
            completed_elearning: 0,
            inverted_referral: 0,
            digital: 0,
            own_sale: 0,
            monthly_goal: 0,
            long_term_bank_sale: 0,
            short_term_bank_sale: 0
        }
    }
}

const user = {
    state: {
        isLoggedIn: !!sessionStorage.getItem('access_token') || !!localStorage.getItem('access_token'),
        user: payload,
        bonus: null,
        ranking: null,
        payoutsHistory: null,
        /** representantive | manager */
        role: 'representantive',
        checkAgreements: null,
        //canLogin: window.e35
        canLogin: true,
        pointsStatisticsLastPeriod: [],
        pointsStatisticsFilterLastPeriod: '',
        pointsStatistics: [],
        pointsStatisticsFilter: '',
    },
    mutations: {
        setLoggedIn: function (state, payload) {
            state.isLoggedIn = payload;
        },
        userUpdate: function (state, payload) {
            state.user = {...state.user, ...payload};
        },
        updatePointsStatistics: function (state, payload) {
            state.pointsStatistics = payload;
        },
        setPointsStatisticsFilter: function (state, payload) {
            state.pointsStatisticsFilter = payload;
        },
        updatePointsStatisticsLastPeriod: function (state, payload) {
            state.pointsStatisticsLastPeriod = payload;
        },
        setPointsStatisticsFilterLastPeriod: function (state, payload) {
            state.pointsStatisticsFilterLastPeriod = payload;
        },
        bonusUpdate: function (state, payload) {
            state.bonus = payload;
        },
        rankingUpdate: function (state, payload) {
            state.ranking = payload;
        },
        payoutsUpdate: function (state, payload) {
            state.payoutsHistory = payload;
        },
        agreementsUpdate: function (state, payload) {
            state.checkAgreements = payload;
        }
    },
    actions: {
        async login (context, payload) {
            return new Promise((resolve, reject) => {
                window.api.post('/auth/login', payload)
                    .then((response) => {
                        if(payload.remember_me){
                            localStorage.setItem('access_token', response.data.data.token);
                        } else {
                            sessionStorage.setItem('access_token', response.data.data.token);
                        }
                        context.commit('setLoggedIn', true);
                        context.dispatch('getUser');
                        router.go(history && history.length > 2 ? -1 : {name: 'home'});
                        resolve(response);
                    })
                    .catch((e) => {
                        if (e.response.status === 401) {
                            if (sessionStorage.getItem('access_token')) {
                                sessionStorage.removeItem('access_token')
                            }
                            if (localStorage.getItem('access_token')) {
                                localStorage.removeItem('access_token')
                            }
                        }
                        reject(e);
                    })
            })
        },
        getAgreements: async function (context) {
            await window.api.get('my-account/check-agreements')
                .then(response => {
                    context.commit('agreementsUpdate', response.data.data.result);
                    // if (response.data.data.result === 1) {
                    //     sessionStorage.setItem('agreements_accepted', 'true');
                    // }
                })
                .catch(() => {})
        },
        logout: async function (context) {
            try{
                await window.api.get('/auth/logout');
            } catch(e){
                //
            }
            sessionStorage.clear();
            localStorage.clear();
            context.commit('setLoggedIn', false);
            router.push({name: 'login'});
        },
        getUser: async function (context) {
            await window.api.get('user/me')
                .then(response => {
                    context.commit('userUpdate', {...payload, ...response.data.data});
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        if (sessionStorage.getItem('access_token')) {
                            sessionStorage.removeItem('access_token')
                        }
                        if (localStorage.getItem('access_token')) {
                            localStorage.removeItem('access_token')
                        }
                        router.go({name: 'login'});
                    }
                })
        },
        getPointsStatistics: function (context){
            context.commit('increaseLoader');
            let filter = '';
            if(context.state.pointsStatisticsFilter !== ''){
                filter = context.state.pointsStatisticsFilter;
            }
            window.api.get('user/score/'+filter)
                .then(response => {
                    context.commit('updatePointsStatistics', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                });
        },
        setPointsStatisticsFilter: function (context, filter){
            context.commit('setPointsStatisticsFilter', filter)
            context.dispatch('getPointsStatistics');

        },
        getPointsStatisticsLastPeriod: function (context){
            context.commit('increaseLoader');
            let filter = '';
            if(context.state.pointsStatisticsFilterLastPeriod !== ''){
                filter = context.state.pointsStatisticsFilterLastPeriod;
            }
            window.api.get('user/score/'+filter)
                .then(response => {
                    context.commit('updatePointsStatisticsLastPeriod', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                });
        },
        setPointsStatisticsFilterLastPeriod: function (context, filter){
            context.commit('setPointsStatisticsFilterLastPeriod', filter)
            context.dispatch('getPointsStatisticsLastPeriod');

        },
    },
    getters: {
        isLoggedIn: function (state) {
            return !!state.isLoggedIn;
        },
        user: function (state) {
            return state.user;
        },
        role: function (state) {
            if(state.user.team_leader === 1){
                return USER_ROLES.MANAGER;
            } else {
                return  USER_ROLES.REPRESENTANTIVE;
            }
        },
        ranking: function (state) {
            return state.ranking;
        },
        canLogin: function (state) {
            return state.canLogin;
        },
        getPointsStatistics: function (state) {
            return state.pointsStatistics;
        },
        getPointsStatisticsLastPeriod: function (state) {
            return state.pointsStatisticsLastPeriod;
        },
    }
}

export default user;
