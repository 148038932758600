const routes = [
    {
        path: '/',
        name: 'home',
        meta: {requiresAuth: true},
        component: () => import('../views/HomeView')
    },
    {
        path: '/news',
        name: 'news',
        meta: {requiresAuth: true},
        component: () => import('../views/NewsView')
    },
    {
        path: '/konto',
        name: 'account',
        meta: {requiresAuth: true},
        component: () => import('../views/AccountView')
    },
    {
        path: '/katalog',
        name: 'catalog',
        meta: {requiresAuth: true},
        component: () => import('../views/CatalogPrizesView')
    },
    {
        path: '/produkt/:id',
        name: 'product',
        props: true,
        meta: {requiresAuth: true},
        component: () => import('../components/modules/prizes/CatalogPrizesDetails')
    },
    {
        path: '/produkt-premium/:id',
        name: 'product-premium',
        props: true,
        meta: {requiresAuth: true},
        component: () => import('../components/modules/prizes/CatalogPrizesPremiumDetails')
    },
    {
        path: '/kontakt',
        name: 'contact',
        meta: {requiresAuth: true},
        component: () => import('../components/modules/ContactComponent')
    },
    {
        path: '/preferencje-cookies',
        name: 'cookies',
        component: () => import('../views/CookiesView')
    },
    {
        path: '/zasady',
        name: 'rules',
        meta: {requiresAuth: true},
        component: () => import('../views/RulesView')
    },
    {
        path: '/rankingi',
        name: 'rankings',
        meta: {requiresAuth: true},
        component: () => import('../views/RankingView')
    },
    {
        path: '/szkolenia',
        name: 'trainings',
        meta: {requiresAuth: true},
        component: () => import('../views/TrainingsView')
    },
    {
        path: '/szkolenie/:id?',
        name: 'training',
        meta: {requiresAuth: true},
        component: () => import('../views/TrainingView')
    },
    {
        path: '/zwyciezcy',
        name: 'winners',
        meta: {requiresAuth: true},
        component: () => import('../views/WinnersView')
    },
    {
        path: '/koszyk/:payed?',
        name: 'basket',
        meta: {requiresAuth: true},
        props: true,
        component: () => import('../views/BasketView')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../components/auth/LoginComponent')
    },
    {
        path: '/retrieve',
        name: 'retrieve',
        component: () => import('../components/auth/RetrievePasswordComponent')
    },
    {
        path: '/reset-hasla/:token/:email',
        name: 'resetPassword',
        props: true,
        component: () => import('../components/auth/ResetPasswordComponent')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../components/auth/RegisterComponent')
    },
    {   path: '/l098098098/:hash?',
        name: 'admin-login',
        meta: {requiresAuth: false},
        component: () => import('../components/auth/AdminLoginPage')
    },
    {
        path : '*',
        name: 'error-404',
        component: () => import('../components/error/Error404.vue')
    }

]

export default routes;
