import axios from 'axios'

const api = axios.create({
    baseURL: (window.apiUrl && window.apiUrl !== '') ? window.apiUrl : "/api",
})

api.interceptors.request.use(
    config => {
        // check if csrf token is set in app and add it to headers
        let tokenCsrf = document.head.querySelector('meta[name="csrf-token"]');
        if (tokenCsrf) {
            config.headers.common['X-CSRF-TOKEN'] = tokenCsrf.content;
        }
        let token = false;
        if (sessionStorage.getItem('access_token')) {
            token = sessionStorage.getItem('access_token')
        } else if(localStorage.getItem('access_token')) {
            token = localStorage.getItem('access_token')
        }

        if (token) {
            config.headers.common["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                case 403:
                    sessionStorage.clear();
                    delete axios.defaults.headers.common['Authorization'];
                    //window.location.reload();
                    break;
            }
        }
        return Promise.reject(error);
    }
);

export default api;
