const payload = {
    id: null,
    title: '',
    short: '',
    content: '',
    published_at: ''
}

const news = {
    state: {
        news:[

        ],
        homeNews: [
            payload
        ]
    },
    mutations: {
        setNews: function (state, payload) {
            state.news = payload;
        },
        setHomeNews: function (state, payload) {
            state.homeNews = payload;
        },
    },
    actions: {
        getNews: function (context) {
            context.commit('increaseLoader');
            window.api.get('article')
                .then(response => {
                    context.commit('setNews', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
        getHomeNews: function (context) {
            context.commit('increaseLoader');
            window.api.get('article?limit=1')
                .then(response => {
                    context.commit('setHomeNews', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
    },
    getters: {
        getNews: function (state) {
            return state.news;
        },
        getHomeNews: function (state) {
            return state.homeNews;
        }
    }
}

export default news;
