const payloads = []

const training = {
    state: {
        training:payloads[0],
        trainings:payloads,
    },
    mutations: {
        setTraining: function (state, payload) {
            state.training = payload;
        },
        setTrainings: function (state, payload) {
            state.trainings = payload;
        },
    },
    actions: {
        getTraining: function (context) {
            context.commit('increaseLoader');
            window.api.get('training')
                .then(response => {
                    context.commit('setTraining', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
        getTrainings: function (context) {
            context.commit('increaseLoader');
            window.api.get('elearning')
                .then(response => {
                    context.commit('setTrainings', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
    },
    getters: {
        getTraining: function (state) {
            return state.training;
        },
        getTrainings: function (state) {
            return state.trainings;
        },
    }
}

export default training;
