<template>
    <div class="basket-icon">
        <img src="@/img/basket.svg" alt="" >
        <div v-if="productCount > 0" class="basket-icon__count">{{ productCount }}</div>
    </div>
</template>

<script>
export default {
    name: "BasketIcon",
    computed: {
        productCount(){
            return this.$store.getters.user.cart_items
        }
    }
}
</script>

<style scoped lang="scss">
    .basket-icon {
        cursor: pointer;
        position: relative;

        &__count {
            width: 24px;
            height: 24px;
            background-color: #0075C9;
            color: #fff;
            font-weight: 700;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: -12px;
            right: -8px;
            border-radius: 50%;
            box-shadow: 0 4px 8px rgba(43,124,189,0.4);
        }
    }
</style>
