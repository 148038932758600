
const payload = {
    id: 1,
    date: '10.12.2022',
    status: 'processed',
    //status: 'in_realization',
    //status: 'delivered',
    status_human: 'W trakcie przetwarzania',
    cart_total: 0,
    products: []
};

const basket = {
    state: {
        productsCount: 0,
        cart: payload,
        delivery:{
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            street: '',
            building_number: '',
            apartment_number: '',
            city: '',
            postal: ''
        },
        orders:[],
        orderResponse: null,
    },
    mutations: {
        setCart(state, payload){
            state.cart = payload;
        },
        setProductsCount(state, payload) {
            state.productsCount = payload;
        },
        setDelivery(state, payload) {
            state.delivery = payload;
        },
        setOrders(state, payload){
            state.orders = payload;
        },
        setOrderResponse(state, payload){
            state.orderResponse = payload;
        }
    },
    actions: {
        updateProductsCount({commit}, payload) {
            commit('setProductsCount', payload);
        },
        addToCart: function (context, payload){
            context.commit('increaseLoader');
            return new Promise((resolve, reject) => {
                window.api.post('cart/add', payload)
                    .then((response) => {
                        context.dispatch('getUser');
                        resolve(response);
                    })
                    .catch((e) => {
                        let message = 'Wystąpił błąd podczas dodawania produktu do koszyka.';
                        if(e.response.data.message !== undefined && e.response.data.message !== ''){
                            message = e.response.data.message;
                        }
                        context.commit('showPopup', {
                            body: '<p class="p-4">' + message + '</p>',
                            padding: true,
                        })
                        reject(e);
                    })
                    .finally(()=>{
                        context.commit('decreaseLoader');
                    })
            })
        },
        getCart: function (context) {
            context.commit('increaseLoader');
            window.api.get('cart')
                .then(response => {
                    context.commit('setCart', {...payload, ...response.data.data})
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                });
        },
        getOrders: function (context) {
            context.commit('increaseLoader');
            window.api.get('order/history')
                .then(response => {
                    context.commit('setOrders', response.data.data)
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                });
        },
        removeFromCart: function (context, payload){
            context.commit('increaseLoader');
            window.api.post('cart/remove', {cart_id: payload})
                .then(() => {
                    context.dispatch('getCart');
                })
                .catch((e) => {
                    let message = 'Wystąpił błąd podczas usuwania produktu z koszyka.';
                    if(e.response.data.message !== undefined && e.response.data.message !== ''){
                        message = e.response.data.message;
                    }
                    context.commit('showPopup', {
                        body: '<p class="p-4">' + message + '</p>',
                        padding: true,
                    })
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                })
        },
        updateCart: function (context, payload){
            context.commit('increaseLoader');
            window.api.post('cart/change', payload)
                .catch((e) => {
                    let message = 'Wystąpił błąd podczas zmiany ilości produktów w koszyka.';
                    if(e.response.data.message !== undefined && e.response.data.message !== ''){
                        message = e.response.data.message;
                    }
                    context.commit('showPopup', {
                        body: '<p class="p-4">' + message + '</p>',
                        padding: true,
                    })
                })
                .finally(()=>{
                    context.commit('decreaseLoader');
                    context.dispatch('getCart'); //reset na froncie do stanu sprzed zmiany
                })
        },
        updateCartDelivery: function (context, payload){
            context.commit('setDelivery', payload);

            //context.commit('increaseLoader');
            // window.api.post('cart-update-delivery', payload)
            //     .then(() => {
            //         context.dispatch('getCart');
            //     })
            //     .catch((e) => {
            //         let message = 'Wystąpił błąd podczas zapisu danych dostawy.';
            //         if(e.response.data.message !== undefined && e.response.data.message !== ''){
            //             message = e.response.data.message;
            //         }
            //         context.commit('showPopup', {
            //             body: '<p class="p-4">' + message + '</p>',
            //             padding: true,
            //         })
            //     })
            //     .finally(()=>{
            //         context.commit('decreaseLoader');
            //     })
        },
        confirmCart: function (context, payload){
            context.commit('increaseLoader');
            context.commit('setOrderResponse', null);
            return new Promise((resolve, reject) => {
                window.api.post('order/make', payload)
                    .then((response) => {
                        context.dispatch('getCart');
                        resolve(response);
                    })
                    .catch((e) => {
                        context.commit('setOrderResponse', {
                            success: false,
                            data: e.response.data
                        });
                        reject(e);
                    })
                    .finally(()=>{
                        context.commit('decreaseLoader');
                    })
            })
        },
    },
    getters:{
        getCart: function (state) {
            return state.cart;
        },
        getDelivery: function (state) {
            return state.delivery;
        },
        getOrders: function (state) {
            return state.orders;
        },
        getOrderResponse: function (state) {
            return state.orderResponse;
        },
        getCartProductsCount(state){
            return state.cart.products !== undefined ? state.cart.products.length : 0
        }
    }
}

export default basket;
