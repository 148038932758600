<template>
    <transition name="fade">
        <div v-if="show" class="cookies">
            <p class="fw-bold mb-2">Polityka dot. plików cookie</p>
            <p>Używamy tylko plików cookie, które są niezbędne, aby umożliwić działanie naszej strony internetowej. Szczegółowe informacje na temat wykorzystywanych przez nas plików cookie znajdują się w naszej <a class="text-white text-decoration-none" target="_blank" :href="publicPath + '/assets/app/files/Polityka_Cookies_v1.pdf?v='+date">Polityce Cookie</a>.</p>
            <button type="button" @click="hide">Zamknij</button>
        </div>
    </transition>
</template>

<script>
export default {
    name: "CookieComponent",
    data: function(){
        return {
            show: false,
            //publicPath: process.env.BASE_URL;
            publicPath: window.location.origin
        }
    },
    computed:{
        date(){
            return new Date().getTime()
        },
    },
    mounted() {
        this.init();
    },
    methods:{
        init(){
            if( !localStorage.getItem('elavon-cookie') ){
                this.show = true;
            }
        },
        hide(){
            localStorage.setItem('elavon-cookie', true);
            this.show = false;
        }
    }
}
</script>
