<template>
    <div v-if="isLoggedIn" :class="['navigation', {'active': open}, {'scroll': scroll}]">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="text-center position-relative">
                        <div class="main-nav d-flex d-lg-block justify-content-between align-items-center">
                            <div class="hamburger" @click="open = !open">
                                <span v-for="(i, index) in 3" :key="index"/>
                            </div>
                            <div v-if="userRole === userRoles.REPRESENTANTIVE" class="user-nav text-end">
                                <basket-icon @click.native="goToBasket"></basket-icon>
                            </div>
                        </div>
                        <div class="logo d-inline-block">
                            <router-link :to="{name: 'home'}">
                                <img src="@/img/MC_Elavon_klub_korzysci_logotyp_czarne_v1_TF.png" alt="" class="w-100">
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" style="position: relative;">
            <div class="col-12">
                <div class="nav">
                    <div class="container">
                        <div class="nav-bg" @click="open = false"/>
                        <nav>
                            <div style="width: 100vw">
                                <div class="container">
                                    <ul v-if="true" class="list-unstyled">
                                        <li>
                                            <router-link :to="{name: 'home'}">Strona główna</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'news'}">Aktualności</router-link>
                                        </li>
                                        <li v-if="userRole === userRoles.REPRESENTANTIVE">
                                            <router-link :to="{name: 'catalog'}">Katalog nagród</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'rankings'}">Rankingi</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'winners'}">Zwycięzcy</router-link>
                                        </li>
                                        <li v-if="userRole === userRoles.REPRESENTANTIVE">
                                            <router-link :to="{name: 'trainings'}">Szkolenia</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'account'}">Twoje konto</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'rules'}">Zasady</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'contact'}">Kontakt</router-link>
                                        </li>
                                        <li>
                                            <a href="/logout" @click.prevent="logout">Wyloguj się</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import {mapState} from "vuex";
import BasketIcon from "@/components/modules/basket/BasketIcon";
import {USER_ROLES} from "@/store/constants";

export default {
    name: "NavigationComponent",
    components: {
        BasketIcon
    },
    data() {
        return {
            open: false,
            scroll: false,
        }
    },
    computed: {
        isLoggedIn: function(){
            return this.$store.getters.isLoggedIn;
        },
        userRole: function (){
            return this.$store.getters.role;
        },
        userRoles: function (){
            return USER_ROLES;
        }
        // ...mapState({
        //     user: state => state.user.payload,
        // }),
        // productsCount: function () {
        //     return this.$store.getters.countProductsInBasket;
        // },
    },
    watch: {
        '$route': function () {
            this.open = false;
        },
        open() {
            if (this.open) {
                document.querySelector('body').style.overflowY  = 'hidden';
            } else {
                document.querySelector('body').style.overflowY  = 'auto';
            }
        }
    },
    methods: {
        logout: function () {
            this.$store.dispatch('logout');
        },
        goToBasket() {
            this.$router.push({name: 'basket'})
        }
    },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";
.navigation {
    width: 100%;
    padding: 24px 0 35px 0;
    background: rgba(255,255,255,0);
    position: relative;
    top: 0;
    left: 0;
    z-index: 999990;
    transition: .2s ease-out;

    .logo {
        width: 100%;
        max-width: 320px;
        position: relative;
        transition: .4s;
    }
    &.scroll{
        padding: 20px 0;
        background: rgba(255,255,255,1);
        box-shadow: 0 0 24px 0 rgba(0,0,0,.2);
        .logo {
            max-width: 140px;
        }
    }
    .main-nav{
        @media (max-width: 991px) {
            margin-bottom: 20px;
        }
    }
    .user-nav {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        @media (max-width: 767px) {
            position: static;
            transform: none;
        }
        & > ul {
            display: block;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                margin: 0 10px;
                position: relative;

                & > ul {
                    padding: 0;
                    margin: 0;
                    background: red;
                    border-radius: 10px;
                    //overflow: hidden;
                    box-shadow: 0 6px 12px 0 rgba(0,0,0,.2);
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    z-index: 999;
                    transform: translate(-50%, 15px);
                    opacity: 0;
                    transition: .2s;
                    visibility: hidden;
                    &::before{
                        content: '';
                        display: block;
                        background: red;
                        width: 16px;
                        height: 16px;
                        border-radius: 3px;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform-origin: 50% 50%;
                        transform: translate(-50%, -6px) rotate(45deg);
                    }

                    li {
                        display: block;
                        text-align: start;
                        white-space: nowrap;
                        margin: 0;
                        a {
                            @extend .f16;
                            font-weight: 900;
                            display: block;
                            padding: 10px 20px;
                            color: #fff;
                            border-radius: 10px;
                            &:hover, &.router-link-exact-active, &.router-link-active {
                                background: green;
                                color: #fff;
                            }
                        }
                    }
                }

                &:hover {
                    & > ul {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                a {
                    @extend .f16;
                    display: block;
                    padding: 5px 0 20px;
                    color: #fff;
                    transition: .2s;
                    text-transform: uppercase;
                }
            }
        }

        .products-count {
            font-weight: bold;
            padding: 0 2px;
            min-width: 14px;
            height: 14px;
            line-height: 14px;
            text-align: center;
            background-color: #BF081B;
            border-radius: 14px;
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(50%);
        }
    }

    .hamburger {
        width: 40px;
        height: 20px;
        z-index: 99;
        cursor: pointer;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);

        &:hover {
            span {
                background: $color-blue;
                transition: .3s ease-out;
                &:nth-child(1) {
                    width: 36px
                }
                &:nth-child(2) {
                    width: 100%
                }
                &:nth-child(3) {
                    width: 32px
                }
            }
        }
        @media (max-width: 767px) {
            position: relative;
            top: auto;
            left: auto;
            transform: none;
        }
        span {
            width: 100%;
            height: 2px;
            background: #000;
            display: block;
            position: absolute;
            left: 0;
            transition: .4s ease-out;

            &:nth-child(1) {
                top: 0;
                width: 100%;
            }

            &:nth-child(2) {
                top: 8px;
                width: 32px;
            }

            &:nth-child(3) {
                top: 16px;
                width: 36px;
            }
        }
        .img-text{
            width: 100%;
            position: absolute;
            left: 0;
            top: 24px;
            transition: .2s ease-out;
        }
    }
    .nav{
        position: relative;
        &-bg{
            width: 100vw;
            height: 100vh;
            background: rgba(255,255,255,.5);
            position: fixed;
            top: 0;
            left: 0;
            visibility: hidden;
            opacity: 0;
            transition: .4s;
        }
        nav{
            width: 40vw;
            height: 100vh;
            padding-top: 150px;
            background: linear-gradient(243deg, rgba(0,100,124,1) 0%, rgba(0,78,97,1) 100%);
            box-shadow: 0 0 24px 0 rgba(0,0,0,.7);
            position: fixed;
            top: 0;
            left: 0;
            overflow-x: hidden;
            overflow-y: auto;
            visibility: hidden;
            opacity: 0;
            transition: .4s;
            scrollbar-color: rgba(0,0,0,.5) rgba(0,0,0,.1);
            border-bottom-right-radius: 30px;
            border-top-right-radius: 30px;
            &::-webkit-scrollbar {
                width: 10px;
                background-color: rgba(0,0,0,.2);
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, .5);
            }
            @media screen and (max-width: 1500px) {
                width: 600px;
            }
            @media screen and (max-width: 1199px) {
                width: 500px;
            }
            @media screen and (max-width: 991px){
                width: 100vw;
                text-align: center;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
            .nav-gradient{
                width: 40vw;
                height: 100%;
                @media (max-width: 1920px) and (min-width: 1400px){
                    width: 20vw;
                }
                @media (max-width: 991px){
                    width: 100vw;
                }
                position: fixed;
                top: 0;
                left: 0;
                pointer-events: none;
            }
            ul{
                //display: inline-block;
                display: flex;
                flex-direction: column;
                margin-bottom: 0;
                text-align: left;
                height: calc(100vh - 170px);
                overflow-x: hidden;
                overflow-y: auto;
                @media (max-width: 991px){
                    text-align: center;
                }
                li{
                    //display: block;
                    display: flex;
                    margin-bottom: 12px;
                    transform: translateX(0);
                    transition: all 0.3s;
                    &:last-of-type {
                        margin-top: 1rem;
                        @media screen and (min-width: 767px) {
                            margin-top: auto;
                        }
                    }
                    &:hover {
                        transform: translateX(10px);
                        a {
                            color: $color-blue-main;
                            &::before {
                                width: 100%;
                            }
                        }
                    }
                    a{
                        @extend .f32;
                        width: fit-content;
                        display: block;
                        font-weight: 700;
                        color: #fff;
                        position: relative;
                        text-decoration: none;
                         &.router-link-exact-active {
                            color: red;
                        }
                        &::before {
                            transition: all 0.3s;
                            content: '';
                            width: 0;
                            height: 3px;
                            background-color: $color-blue-main;
                            position: absolute;
                            bottom: -5px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    &.active {
        .hamburger {
            position: absolute;
            top: 36px;
            left: 0;
            transition: all 0s;
            @media screen and (max-width: 991px){
                top: 44px;
            }
            span {
                background: #fff;
                &:nth-child(1) {
                    transform: rotate(45deg) translate(9px, 12px);
                    height: 3px;
                    width: 20px;
                }

                &:nth-child(2) {
                    height: 3px;
                    width: 100%;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg) translate(10px, -12px);
                    height: 3px;
                    width: 20px;
                }
            }
            img {
                transform: scale(0);
            }
        }
        .nav {
            &-bg{
                visibility: visible;
                opacity: 1;
            }
            nav {
                visibility: visible;
                opacity: 1;
                & > ul li > ul {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}

</style>
