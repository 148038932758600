import {LINK_TARGET_BYPASS} from "@/store/constants";

const registration = {
    state:{
        email: '',
        register_code: '',
        step: 1,
        agreement_1:{
            value: false,
            text: "Zapoznałam/em się z&nbsp;<a "+LINK_TARGET_BYPASS+"='_blank' class='color-white' href='" + window.location.origin + "/assets/app/files/TF_Regulamin__Klub_Korzyści_Elavon_II.pdf?v=" +  new Date().getTime() +"' style='font-weight: 700'>Regulaminem</a> programu „Klub Korzyści Elavon” i&nbsp;akceptuję jego warunki."
        },
        agreement_2:{
            value: false,
            text: "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Elavon Financial Services Designated Activity Company (Spółka z&nbsp;Ograniczoną Odpowiedzialnością o&nbsp;Wyznaczonym Przedmiocie Działalności) Oddział w&nbsp;Polsce, ul.&nbsp;Puławska&nbsp;17, 02-515 Warszawa („Elavon”), w&nbsp;tym przez Albedo Marketing Sp.&nbsp;z&nbsp;o.o., ul.&nbsp;Góralska&nbsp;3, 60-623 Poznań która działa na zlecenie Elavon jako podmiot przetwarzający dane -  w&nbsp;celu mojego uczestnictwa w&nbsp;konkursie „Klub Korzyści Elavon” i&nbsp;realizacji postanowień jego regulaminu. Odwołanie zgody może nastąpić w&nbsp;każdym czasie, poprzez Klub kontakt@konkurs-klubkorzysci.pl. Zasady przetwarzania danych osobowych dostępne są <a "+LINK_TARGET_BYPASS+"='_blank' class='color-white' href='" + window.location.origin + "/assets/app/files/Regulamin_Zasady_Przetwarzania_Danych.pdf?v=" +  new Date().getTime() +"' style='font-weight: 700'>tutaj</a>."
        },
        agreement_3:{
            value: false,
            text: "W przypadku zdobycia Nagrody, niniejszym oświadczam, że:<br/>" +
                "• moja akceptacja Nagrody jest zgodna z&nbsp;kodeksem biznesowym i/lub polityką mojej organizacji (w&nbsp;tym między innymi z&nbsp;kodeksem postępowania, polityką etyczną lub polityką przeciwdziałania przekupstwu i&nbsp;korupcji lub podobnymi regulacjami) oraz ze wszystkimi obowiązującymi przepisami dotyczącymi przeciwdziałania przekupstwu oraz z&nbsp;prawem i&nbsp;regulacjami dotyczącymi korupcji.<br/>" +
                "• W&nbsp;przypadku, gdy będę musiał uzyskać zgodę mojej organizacji na przyjęcie nagrody, powiadomię o&nbsp;tym firmę Mastercard i&nbsp;jeśli nie otrzymam odpowiedniej zgody, niezwłocznie zwrócę nagrodę.<br/>" +
                "• Nagroda nie wpływa – i&nbsp;nie będzie – w&nbsp;niewłaściwy sposób wpływać na moje działania lub decyzje na rzecz Mastercard lub jakiejkolwiek strony trzeciej działającej w&nbsp;imieniu Mastercard.<br/>" +
                "• Nagroda będzie przeznaczona do użytku osobistego i&nbsp;niezbywalna. Nagroda nie może być odsprzedana ani dzielona z&nbsp;konkurentami Mastercard."
        }
    },
    mutations:{
        setStep: function (state, payload){
            state.step = payload;
        },
        setEmail: function (state, payload){
            state.email = payload;
        },
        setAgreement1Value: function (state, payload){
            state.agreement_1.value = payload;
        },
        setAgreement2Value: function (state, payload){
            state.agreement_2.value = payload;
        },
        setAgreement3Value: function (state, payload){
            state.agreement_3.value = payload;
        },
        setRegisterCode: function (state, payload){
            state.register_code = payload;
        }
    },
    actions:{
        registration: function(context, payload){
            console.log(context, payload);
        }
    },
    getters:{
        getAgreement_1: function(state){
            return state.agreement_1;
        },
        getAgreement_2: function(state){
            return state.agreement_2;
        },
        getAgreement_3: function(state){
            return state.agreement_3;
        },
        getEmail: function(state){
            return state.email;
        },
        getRegisterCode: function(state){
            return state.register_code;
        },
        getStep: function(state){
            return state.step;
        }
    }
}

export default registration;
